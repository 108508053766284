import React from "react";
import ashleyNotifi from "../Images/ashley-notifi.png";
function SampBox() {
  return (
    <div className="row">
      <div className="col-md-1" >
        <img src={ashleyNotifi} alt="ashley-notifi.png" width="55" height="66" />
      </div>
      <div className="col-md-11 mt-lg-4" style={{ fontFamily: "Arial" }}>
        Please make sure to monitor your SPAM and JUNK folders as well.
      </div>
    </div>
  );
}
export default SampBox;
