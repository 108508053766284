import { CKEditor } from "ckeditor4-react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { plasticAddSite } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import he from "he";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

export default function NewNewsletter() {
  const state = useSelector((state) => state.stateVals);

  const { customerSiteId, customer_id } = state;

  const [loading, setLoading] = useState(false);
  const [editorData, setEditorData] = useState("");

  useEffect(() => {
    if (customer_id) {
      const getPypSite = async () => {
        try {
          setLoading(true);

          const responce = await AdminListService.listPlasticById(
            customerSiteId
          );

          if (responce.status === 200) {
            setLoading(false);
            setValues({
              id: responce.data.data.Adata.AdminId,
              siteName: responce.data.data.Sdata.SiteName,
              userName: responce.data.data.Adata.UserName,
              password: responce.data.data.Adata.Password,
              email: responce.data.data.Adata.Email,
              visio: responce.data.data.Sdata.na_visio,
              pedType: responce.data.data.Sdata.product_type,
              subject: responce.data.data.Sdata.email_subject,
              body: responce.data.data.Sdata.email_body,
            });
            const bodyData = he.decode(responce.data.data.Sdata.email_body);
            setEditorData(bodyData);
          }
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };

      getPypSite();
    }

    // eslint-disable-next-line
  }, []);

  const updatePlasticSite = async (values) => {
    setLoading(true);

    try {
      const responce = await AdminListService.sitePlasticUpdate(
        customerSiteId,
        values,
        customer_id
      );

      if (responce.status === 200) {
        setLoading(false);

        toast.success("Updated successfully!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      id: "",
      siteName: "",
      userName: "",
      password: "",
      email: "",
      visio: "",
      pedType: "",
      subject: "",
      body: "",
    },
    validationSchema: plasticAddSite,
    onSubmit: (values) => {
      updatePlasticSite(values);
    },
  });
  return loading ? (
    <Skeleton count={1} height="400px" />
  ) : (
    <div className="card">
      <div className="card-body">
        <form className="row g-3" onSubmit={handleSubmit} noValidate>
          {/* <div className="col-12 col-md-4">
            <label className="form-label">Site Name </label>
            <input
              type="text"
              className="form-control"
              placeholder="Site Name"
              name="siteName"
              value={values.siteName || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.siteName && touched.siteName ? (
              <span className="text-danger">{errors.siteName}</span>
            ) : null}
          </div>
          <div className="col-12 col-md-4">
            <label className="form-label">Username</label>
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              name="userName"
              value={values.userName || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.userName && touched.userName ? (
              <span className="text-danger">{errors.userName}</span>
            ) : null}
          </div>
          <div className="col-12 col-md-4">
            <label className="form-label">Password </label>
            <input
              type="text"
              className="form-control"
              placeholder="Password"
              name="password"
              value={values.password || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.password && touched.password ? (
              <span className="text-danger">{errors.password}</span>
            ) : null}
          </div>
          <div className="col-12 col-md-4">
            <label className="form-label">Email </label>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              value={values.email || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <span className="text-danger">{errors.email}</span>
            ) : null}
          </div>

          <div className="col-12 col-md-4">
            <label className="form-label">Na-visio Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Na-visio Number"
              name="visio"
              value={values.visio || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.visio && touched.visio ? (
              <span className="text-danger">{errors.visio}</span>
            ) : null}
          </div>
          <div className="col-12 col-md-4">
            <label className="form-label">Product Type</label>
            <select
              className="form-select"
              name="pedType"
              value={values.pedType || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Product Type</option>
              <option value="Ordinary PYP">Ordinary PYP</option>
              <option value="Gorilla PYP">Gorilla PYP</option>
            </select>
            {errors.pedType && touched.pedType ? (
              <span className="text-danger">{errors.pedType}</span>
            ) : null}
          </div> */}

          <div className="col-md-8 col-lg-9">
            <label htmlFor="formFileMultiple" className="form-label">
              Email Subject
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Subject"
              name="subject"
              value={values.subject || ""}
              onChange={handleChange}
            />
            {errors.subject && touched.subject ? (
              <span className="text-danger">{errors.subject}</span>
            ) : null}
          </div>
          <div className="col-md-4 col-lg-3">
            <button className="btn btn-primary form-btn" type="submit">
              Update Email Template
            </button>
          </div>

          <div className="col-12 col-md-12" id="submit-news">
            <label htmlFor="formFileMultiple" className="form-label">
              Email body
            </label>
            {editorData && (
              <CKEditor
                initData={editorData}
                config={{
                  allowedContent: true,
                  toolbar: [
                    ["Source"],
                    ["Styles", "Format", "Font", "FontSize"],
                    [
                      "Cut",
                      "Copy",
                      "Paste",
                      "PasteText",
                      "PasteFromWord",
                      "-",
                      "Undo",
                      "Redo",
                    ],
                    ["Bold", "Italic", "Strike", "UnderLine", "RemoveFormat"],
                    ["Link", "Unlink", "Anchor"],
                    [
                      "NumberedList",
                      "BulletedList",
                      "-",
                      "Outdent",
                      "Indent",
                      "-",
                      "Blockquote",
                      "CreateDiv",
                      "-",
                      "JustifyLeft",
                      "JustifyCenter",
                      "JustifyRight",
                      "JustifyBlock",
                      "-",
                      "BidiLtr",
                      "BidiRtl",
                      "Language",
                    ],
                    ["EasyImageUpload"],
                    ["TextColor", "BGColor"],
                    ["Maximize", "ShowBlocks"],
                  ],
                  extraPlugins: "easyimage",
                  removePlugins: "image",
                  cloudServices_uploadUrl:
                    "https://33333.cke-cs.com/easyimage/upload/",
                  cloudServices_tokenUrl:
                    "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                }}
                onInstanceReady={(e) => {
                  var data = e.editor.getData();
                  const bodyData = he.encode(data);

                  setFieldValue("body", bodyData);
                }}
                onChange={(e) => {
                  var data = e.editor.getData();
                  const bodyData = he.encode(data);

                  setFieldValue("body", bodyData);
                }}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
