import React from "react";
import { useState, useEffect } from "react";
import "./style.css";
// import Header from "./Header";
import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import Recaptcha from "react-recaptcha";
import { Helmet } from "react-helmet";
import LogService from "../services/log.service";

// import axios from "axios";

function Index(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Register";
  const navigate = useNavigate();
  const [website, setWebsite] = useState(window.location.href);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("return_page", "true");
    // props.history.push("/register/RegisterForm");
    navigate("/register/registration-form");
    // <--- The page you want to redirect your user to.
  };
  useEffect(() => {
    if (localStorage.getItem("return_page") === "true") {
      navigate("/register/registration-form");
    } else {
      localStorage.setItem("return_page", "false");
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <Header /> */}
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              <h2 className="register_lower" style={{ textAlign: "center" }}>
                {/* // ! First 1 OFF */}
                {/* Ashley and Elite Rewards are proud supporters of the American
                Cancer Society. */}
                {/* // ? First 2 ON */}
                {/* Ashley and Elite Rewards are proud supporters of the American
                Heart Association's Life Is Why campaign. */}
                {/* // ! First 3 OFF */}
                {/* Ashley and Elite Rewards are proud to support our
                partners who are dedicated to creating diverse, equal, and
                inclusive communities and opportunities. */}
                {/* // ! First 4 OFF */}
                {/* Ashley and Elite Rewards are proud to support Junior Achievement
                to prepare our youth for their successful futures. */}
                {/* // ! First 5 OFF */}
                {/* Ashley and Elite Rewards are proud to support the American
                Cancer Society to make a life changing difference. */}
                {/* // ! First 6 OFF */}
                You dream. Together we give. Ashley and Elite Rewards are proud
                to support organizations making a difference in our local
                communities.
              </h2>
              <div className="row">
                <div className="col-md-12" id="text">
                  <p className="register-p">
                    {" "}
                    {/* // ! First 1 OFF */}
                    {/* Would you be interested in giving 8-10 minutes of your time
                    to improve your sleep and be part of a movement to help end
                    breast cancer? You’ll get a $10 gift card when you complete
                    a mattress comfort test rest, and we’ll also give $10 to the
                    American Cancer Society. Just a few moments of your time can
                    help support breast cancer research, including access to
                    screenings and transportatiorn to teatments. */}
                    {/* // ? First 2 ON */}
                    {/*  */}
                    {/* // ! First 3 OFF */}
                    {/* You Get $10, We Give $10. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and Ashley
                    Furniture will make Would you be interested in giving 8-10 minutes to improve
                    your sleep and also help support our fight to end heart
                    disease? We are offering a 25$ gift card to do a test rest
                    in our mattress gallery and also will matching that gift
                    card value as a donation to American Heart Association. Just
                    a few moments of your time can help improve your sleep and
                    help save a life!a $10 donation to non-profit partners
                    who are promoting acceptance and inclusion, empowering small
                    businesses, and advancing social and economic equality. Just
                    a few moments of your time can help improve your sleep and
                    impact change in our community. */}
                    {/* // ! First 4 OFF */}
                    {/* You Get $10, We Give $10. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and Ashley
                    Furniture will make a $10 donation to Junior Achievement, to
                    inspire the dreams of our next generation. Just a few
                    moments of your time can help improve your sleep and impact
                    change in our community. */}
                    {/* // ! First 5 OFF */}
                    {/* You Get $10, We Give $10. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and Ashley
                    Furniture will make a $10 donation to the American Cancer
                    Society. It’s a ride to treatment, it helps fund lodging, it
                    means early detection, it’s an end to breast cancer. Just a
                    few moments of your time can help improve your sleep and
                    impact families in our community. */}
                    {/* You Get $10, We Give $10. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and Ashley
                    will make a $10 donation back to the community. Through this
                    program, $1.5 million was donated in 2023, impacting
                    multiple charities from American Cancer Society to Junior
                    Achievement. This year, the goal is to raise more, increase
                    partner reach, and help as many people as possible.
                    Together. Just a few moments of your time can help improve
                    your sleep and impact families in our community. */}
                    You Get $10, We Give $10. Would you be interested in giving
                    10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and Ashley
                    will make a $10 donation back to the community. Through this
                    program, $1.5 million was donated in 2023, impacting
                    multiple charities from American Cancer Society to Junior
                    Achievement. This year, the goal is to raise more, increase
                    partner reach, and help as many people as possible.
                    Together. Just a few moments of your time can help improve
                    your sleep and impact families in our community.
                  </p>
                </div>
              </div>
              <br />
              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="CONTINUE"
                className="sub-btn button"
              ></input>
            </form>
          </ul>
        </div>
      </div>

      <Footer2 />
    </>
  );
}
export default Index;
